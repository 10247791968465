.form {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;

  label {
    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 30px;
    color: #3f4144;
    margin-bottom: 10px;
  }
  select {
    &.input-item {
      width: auto;
      height: 50px;
      padding: 10px 10px;
      background: #f6f6f6;
      border-radius: 5px;
      border: none;
    }

    &:focus {
      outline: none;
      border: none;
    }
  }

  .input-invalid {
    font-size: 14px;
  }
}
