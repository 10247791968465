.yearbook-teacher-list {
  h1 {
    font-family: Rubik;
    font-style: normal;
    font-weight: bold;
    font-size: 28px;
    line-height: 33px;
    letter-spacing: -0.04em;
    color: #3F4144;
    margin-bottom: 30px;
  }

  .content-card {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 240px;
    background: #FFFFFF;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    font-family: Rubik;
    font-style: normal;
    font-size: 18px;
    line-height: 21px;
    letter-spacing: -0.04em;
    color: #3F4144;
    margin-bottom: 20px;

    .input-group {
      display: flex;
      flex-direction: column;
      width: 374px;

      label {
        font-family: Rubik;
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 30px;
        color: #3f4144;
        margin-bottom: 10px;
      }

      select {
        &.input-item {
          width: auto;
          height: 50px;
          padding: 10px 10px;
          margin-bottom: 20px;
          background: #f6f6f6;
          border: none;
        }
      }

      button {
        &.submit-button {
          font-weight: 600;
          font-size: 18px;
          line-height: 30px;
          width: auto;
          height: 50px;
          border: none;
          border-radius: 5px !important;
          margin-top: 15px;
          background: #0f75bc;
          color: #ffffff;
        }
      }
    }
  }
}