@media screen and (max-width: 600px) {
  .gallery-FE {
    img {
      height: 90%;
      margin-left: 4%;
      width: 90%;
      border-radius: 5px;
      cursor: pointer;
    }
  }
}
.gallery-FE {
  img {
    height: 90%;
    width: 90%;
    border-radius: 5px;
    cursor: pointer;
  }
}
