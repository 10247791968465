//mobile
@media only screen and (max-width: 600px) {
  .react-pdf__Page__canvas {
    display: block !important;
    user-select: none !important;
    width: 338px !important;
    height: 842px !important;
  }

  .yearbook-FE {
    .image-wrappers {
      width: 100%;
      padding: 20px;
      background-color: #f6f6f6;
      border-radius: 10px;
      position: relative;
      margin-bottom: 50px;

      .inner-doc {
        position: relative;
      }

      img {
        border-radius: 10px;
        width: 100%;
      }

      .button-arrow {
        width: 65px;
        height: 65px;
        background: #ffffff;
        box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.08);
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        position: absolute;
        z-index: 1;
        bottom: 50%;
        cursor: pointer;

        &.left {
          left: -3px;
        }

        &.right {
          right: -3px;
        }
      }
    }

    .buttons-yearbook {
      .wrapper-page {
        border: 2px solid #0f75bc;
        box-sizing: border-box;
        border-radius: 5px;
        padding: 16px 25px;
        display: flex;
        align-items: center;
        justify-content: center;

        span {
          font-family: Rubik;
          font-style: normal;
          font-weight: bold;
          font-size: 18px;
          line-height: 21px;
          text-align: center;
          letter-spacing: -0.04em;
          color: #0f75bc;
        }
      }

      .zoom-wrapper {
        border: 2px solid #0f75bc;
        box-sizing: border-box;
        border-radius: 5px;
        padding: 16px 0;
        display: flex;
        align-items: center;
        justify-content: space-between;

        span {
          font-family: Rubik;
          font-style: normal;
          font-weight: bold;
          font-size: 18px;
          line-height: 21px;
          text-align: center;
          letter-spacing: -0.04em;
          color: #0f75bc;
          border-right: 2px solid #0f75bc;
          border-left: 2px solid #0f75bc;
          padding: 0 25px;
        }

        .button-adjust {
          width: 28px;
          height: 28px;
          border-radius: 50%;
          border: 2px solid #0f75bc;
          box-sizing: border-box;
          display: flex;
          align-items: center;
          cursor: pointer;
          justify-content: center;
          margin: 0 25px;

          &.plus {
            background-color: #0f75bc;
          }
        }
      }
    }

    .other-buttons {
      display: flex;
      position: relative;

      .others {
        border: 2px solid #0f75bc;
        box-sizing: border-box;
        border-radius: 5px;
        padding: 16px 25px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        margin-right: 20px;

        //   &:last-child {
        //     margin-right: 0;
        //   }
      }

      .shares {
        position: absolute;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
        top: -180px;
        border-radius: 5px;
      }

      .makeStyles-container-1 {
        padding: 15px;
      }

      .makeStyles-iconContainer-3 {
        padding-top: 0;
      }
    }
  }
}

//web
@media only screen and (min-width: 900px) {
  .yearbook-FE {
    .image-wrappers {
      width: 100%;
      padding: 55px;
      background-color: #f6f6f6;
      border-radius: 10px;
      position: relative;
      margin-bottom: 50px;

      .inner-doc {
        position: relative;
      }

      img {
        border-radius: 10px;
        width: 100%;
      }

      .button-arrow {
        width: 65px;
        height: 65px;
        background: #ffffff;
        box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.08);
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        position: absolute;
        z-index: 1;
        bottom: 50%;
        cursor: pointer;

        &.left {
          left: -30px;
        }

        &.right {
          right: -30px;
        }
      }
    }

    .buttons-yearbook {
      .wrapper-page {
        border: 2px solid #0f75bc;
        box-sizing: border-box;
        border-radius: 5px;
        padding: 16px 25px;
        display: flex;
        align-items: center;
        justify-content: center;

        span {
          font-family: Rubik;
          font-style: normal;
          font-weight: bold;
          font-size: 18px;
          line-height: 21px;
          text-align: center;
          letter-spacing: -0.04em;
          color: #0f75bc;
        }
      }

      .zoom-wrapper {
        border: 2px solid #0f75bc;
        box-sizing: border-box;
        border-radius: 5px;
        padding: 16px 0;
        display: flex;
        align-items: center;
        justify-content: space-between;

        span {
          font-family: Rubik;
          font-style: normal;
          font-weight: bold;
          font-size: 18px;
          line-height: 21px;
          text-align: center;
          letter-spacing: -0.04em;
          color: #0f75bc;
          border-right: 2px solid #0f75bc;
          border-left: 2px solid #0f75bc;
          padding: 0 25px;
        }

        .button-adjust {
          width: 28px;
          height: 28px;
          border-radius: 50%;
          border: 2px solid #0f75bc;
          box-sizing: border-box;
          display: flex;
          align-items: center;
          cursor: pointer;
          justify-content: center;
          margin: 0 25px;

          &.plus {
            background-color: #0f75bc;
          }
        }
      }
    }

    .other-buttons {
      display: flex;
      position: relative;

      .others {
        border: 2px solid #0f75bc;
        box-sizing: border-box;
        border-radius: 5px;
        padding: 16px 25px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        margin-right: 20px;

        //   &:last-child {
        //     margin-right: 0;
        //   }
      }

      .shares {
        position: absolute;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
        top: -180px;
        border-radius: 5px;
      }

      .makeStyles-container-1 {
        padding: 15px;
      }

      .makeStyles-iconContainer-3 {
        padding-top: 0;
      }
    }
  }
}


