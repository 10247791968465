.layout-admin {
  display: flex;

  .sidebar {
    width: 400px;
    position: fixed;
    height: 100vh;
    padding: 35px 16px;
    box-shadow: -2px 4px 30px rgba(0, 0, 0, 0.1);

    .menu-group {
      margin-top: 60px;
    }

    .image-sidebar {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 25px;
      padding-bottom: 20px;
      border-bottom: 2px solid #f6f6f6;

      img {
        width: 200px;
      }
    }
  }

  .content {
    padding: 30px 40px;
    width: 100%;
    margin-left: 400px;

    .header-admin {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 20px;
      .search {
        border: 3px solid #e8e8e8;
        box-sizing: border-box;
        border-radius: 5px;
        display: flex;
        align-items: center;
        padding: 5px;
        width: 400px;

        input {
          border: none;
          padding-left: 10px;
          width: 100%;

          &:focus {
            border: none;
            outline: none;
          }
        }

        .button-search {
          width: 100px;
          margin-left: 20px;
        }
      }

      .wrapper-profile {
        position: relative;

        .profil {
          display: flex;
          align-items: center;
          p {
            &.nama {
              font-family: Rubik;
              font-style: normal;
              font-weight: 500;
              font-size: 18px;
              line-height: 21px;
              text-align: right;
              letter-spacing: -0.04em;
              margin-bottom: 0;
              color: #3f4144;
              margin-right: 16px;
            }
          }
          .icon {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 40px;
            height: 40px;
            border-radius: 50%;
            background-color: #0f75bc;
            color: #fff;
            cursor: pointer;
          }
        }

        .dropdown-profile {
          z-index: 99999;
          margin-top: 20px;
          position: absolute;
          background: #ffffff;
          box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
          border-radius: 5px;
          font-family: Rubik;
          right: 0;
          font-style: normal;
          font-size: 18px;
          line-height: 21px;
          letter-spacing: -0.04em;
          color: #3f4144;
          padding: 20px;

          .profile-btn {
            display: flex;
            align-items: center;
            cursor: pointer;
            border-radius: 5px;
            border-bottom: 1px solid #e8e8e8;

            &:hover {
              background-color: #e8e8e8;
            }

            p {
              cursor: pointer;
              margin-bottom: 0;
              padding-left: 10px;
            }
          }

          .logout-btn {
            display: flex;
            align-items: center;
            color: red;
            cursor: pointer;
            border-radius: 5px;
            // border-bottom: 1px solid #e8e8e8;

            &:hover {
              background-color: #e8e8e8;
            }

            p {
              cursor: pointer;
              margin-bottom: 0;
              padding-left: 10px;
            }
          }
        }
      }
    }
  }
}
