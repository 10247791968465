.gallery-edit {
  h2 {
    font-family: Rubik;
    font-style: normal;
    font-weight: bold;
    font-size: 28px;
    line-height: 33px;
    letter-spacing: -0.04em;
    color: #3f4144;
    margin-bottom: 30px;
  }

  .content-gallery {
    box-shadow: 0px 0px 10px rgba(124, 87, 87, 0.1);
    background: #ffffff;
    .content-card {
      display: flex;
      cursor: pointer;
      border-radius: 5px;
      font-family: Rubik;
      font-style: normal;
      font-weight: bold;
      font-size: 18px;
      line-height: 21px;
      letter-spacing: -0.04em;
      color: #3f4144;

      .icon {
        color: #0f75bc;
        margin-right: 5px;
      }

      .card-item {
        margin-top: 17px;
      }

      .card-name {
        padding: 23px;
        width: 35%;

        .code {
          font-weight: normal;
        }
      }

      .card-photo {
        padding: 23px;
        display: flex;
        align-self: center;
        align-items: center;
        border-left: 2px solid #cacccf;
        height: 60px;
        width: 25%;
      }

      .card-status {
        padding: 23px;
        display: flex;
        align-self: center;
        align-items: center;
        border-left: 2px solid #cacccf;
        height: 60px;
        width: 20%;
      }

      .card-action {
        width: 20%;
        padding: 23px;
        display: flex;
        align-items: center;
        justify-content: flex-end;

        .button-delete {
          width: 40px;
          height: 40px;
          border: 2px solid #0f75bc;
          color: #0f75bc;
          box-sizing: border-box;
          border-radius: 5px;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: 10px;
          cursor: pointer;

          &.active {
            color: #ffffff;
            background: #f08a2b;
            border: none;
          }
        }
      }
    }

    .details {
      max-width: 400px;
      margin: auto;

      .button-content {
        margin-top: 30px;

        label {
          font-family: Rubik;
          font-style: normal;
          font-weight: 500;
          font-size: 18px;
          line-height: 30px;
          color: #3f4144;
        }

        .cards {
          display: flex;
          overflow-x: scroll;
          margin-bottom: 40px;
        }

        .buttons {
          .button-cancel {
            border: 2px solid #0f75bc;
            box-sizing: border-box;
            border-radius: 5px;
            font-family: Rubik;
            font-style: normal;
            font-weight: bold;
            font-size: 16px;
            line-height: 30px;
            text-align: center;
            color: #0f75bc;
            padding: 10px;
            margin-right: 10px;
          }

          .button-save {
            border: 2px solid #0f75bc;
            box-sizing: border-box;
            border-radius: 5px;
            font-family: Rubik;
            font-style: normal;
            font-weight: bold;
            font-size: 16px;
            line-height: 30px;
            text-align: center;
            color: #fff;
            padding: 10px;
            background-color: #0f75bc;
          }
        }
      }

      .images {
        .image-wrapper {
          position: relative;
          img {
            width: 100%;
            border-radius: 5px;
          }

          .trash-wrapper {
            position: absolute;
            background-color: #0f75bc;
            bottom: 10px;
            right: 15px;
            width: 25px;
            height: 25px;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
          }
        }

        .upload-field {
          height: 100%;
          min-height: 200px;
          width: 100%;
          border: 2px dotted #cacccf;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          border-radius: 5px;
        }
      }
    }
  }
}
