.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

canvas.react-pdf__Page__canvas {
  margin: auto;
}

.transparent {
  overflow: hidden;
  position: absolute;
  background-color: #000;
  opacity: 0.2;
  z-index: 99999;
  height: 100vh;
  width: 100vw;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
}

.transparent h2 {
  /* opacity: 10 !important; */
  color: #fff;
  z-index: 9999999999999999;
}

/* For pdf */
.pdf-viewer-page .react-pdf__Page {
  margin-top: 10px;
}

.pdf-viewer-page .react-pdf__Page__textContent {
  border: 1px solid darkgrey;
  box-shadow: 5px 5px 5px 1px #ccc;
  border-radius: 5px;
}

.pdf-viewer-page .react-pdf__Page__annotations.annotationLayer {
  padding: 20px;
}

.pdf-viewer-page .react-pdf__Page__canvas {
  margin: 0 auto;
}

/* For all pages */
.all-page-container {
  height: 100%;
  max-height: 500px;
  overflow: auto;
}

@media print {
  body {
    display: none;
  }
}

.pdf-viewer-page .react-pdf__Page__canvas {
  pointer-events: none;
}