//mobile
@media only screen and (max-width: 600px) {
  .help {
    position: fixed;
    height: 75px;
    right: 17px;
    bottom: 80px;
    color: black;
    font-family: Rubik;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 21px;
    letter-spacing: -0.04em;
    z-index: 999999;
  }

  .floating-whatsappx{
    position: fixed;
    width: 53px;
    height: 51px;
    right: 3px;
    bottom: 11px;
    z-index: 100;
    cursor: pointer;
  }

  .floating-whatsapp-yearbook {
    position: absolute;
    width: 53px;
    height: 51px;
    right: 3px;
    margin-top: 5%;
    z-index: 100;
    cursor: pointer;

    // position: fixed;
    // height: 54px;
    // right: 9px;
    // bottom: 93.3%;
    // z-index: 999999;
    // cursor: pointer;

    // height: 54px;
    // right: 9px;
    // bottom: 93.3%;
    // // z-index: 999999;
    // cursor: pointer;
  }

  .floating-whatsapp-alumni {
    position: fixed;
    width: 53px;
    height: 51px;
    right: 3px;
    bottom: 1px;
    z-index: 100;
    cursor: pointer;
  }

  .floating-whatsapp-teacher {
    position: fixed;
    width: 53px;
    height: 51px;
    right: 3px;
    bottom: 1px;
    z-index: 100;
    cursor: pointer;
  }
  .floating-whatsapp-galery {
    position: fixed;
    width: 53px;
    height: 51px;
    right: 3px;
    bottom: 1px;
    z-index: 100;
    cursor: pointer;
  }
}


@media only screen and (min-width: 992px) {
  .help {
    position: fixed;
    height: 75px;
    right: 17px;
    bottom: 80px;
    color: black;
    font-family: Rubik;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 21px;
    letter-spacing: -0.04em;
    z-index: 999999;
  }

  .floating-whatsapp {
    position: fixed;
    // width: 75px;
    height: 100px;
    right: 28px;
    bottom: 50px;
    z-index: 999999;
    cursor: pointer;
  }
}
