.welcome-page {
  .welcome-label {
    p {
      font-family: Rubik;
      font-style: normal;
      font-weight: bold;
      font-size: 18px;
      line-height: 30px;
      text-align: center;
      color: #3f4144;
    }
  }

  .card-content {
    padding: 30px 42px;

    h2 {
      text-align: center;
      font-family: Rubik;
      font-style: normal;
      font-weight: bold;
      font-size: 24px;
      line-height: 45px;
      text-align: center;
      color: #3f4144;
      margin-bottom: 16px;
    }

    .form-input {
      label {
        font-family: Rubik;
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 30px;
        color: #3f4144;
        margin-bottom: 20px;
      }

      select {
        background: #f6f6f6;
        border-radius: 5px;
        width: 100%;
        padding: 10px 16px;
        font-family: Rubik;
        font-style: normal;
        font-weight: 300;
        font-size: 18px;
        line-height: 30px;
      }
    }

    .buttons {
      margin-top: 40px;
      .btn-continue {
        background: #0f75bc;
        border-radius: 5px;
        font-family: Rubik;
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 30px;
        text-align: center;
        color: #ffffff;
        padding: 10px 0;
        width: 100%;
        border: none;
        outline: none;
        margin-bottom: 16px;
      }

      .btn-logout {
        border: 2px solid #0f75bc;
        box-sizing: border-box;
        border-radius: 5px;
        font-family: Rubik;
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 30px;
        text-align: center;
        color: #0f75bc;
        width: 100%;
        padding: 10px 0;
      }
    }
  }
}
