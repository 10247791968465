.content-card-school {
  border: 1.5px solid #e8e8e8;
  box-sizing: border-box;
  border-radius: 5px;
  padding: 10px;
  min-width: 280px;
  max-width: 280px;
  margin-right: 16px;

  .input-plus {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 150px;
    border-radius: 5px;
    background: #f6f6f6;
    cursor: pointer;
  }

  img {
    border-radius: 5px;
  }

  .details {
    .item {
      p {
        background-color: #f6f6f6;
        padding: 10px;
        border-radius: 5px;
      }
    }

    .icon-trash {
      background-color: #e7f2f9;
      display: flex;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      justify-content: center;
      align-items: center;
      margin: auto;
      cursor: pointer;
    }
  }
}
