.menu-item-sidebar {
  display: flex;
  align-items: center;
  margin-bottom: 0;
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  letter-spacing: -0.04em;
  padding: 15px;
  color: #3f4144;
  cursor: pointer;
  border: 3px solid transparent;
  text-decoration: none;

  p {
    cursor: pointer;
    margin-bottom: 0;
    padding-left: 11px;
  }

  &.active {
    color: #0f75bc;
    border: 3px solid #0f75bc;
    box-sizing: border-box;
    border-radius: 5px;
  }
}
