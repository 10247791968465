.card-content {
  width: 440px;
  background: #ffffff;
  border-radius: 10px;

  .content-login {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 20px 30px 20px 30px;

    .title {
      font-family: Rubik;
      font-style: normal;
      font-weight: bold;
      font-size: 24px;
      line-height: 45px;
      text-align: center;
      color: #3f4144;
    }

    .input-group {
      display: flex;
      flex-direction: column;

      .input-field {
        display: flex;
        flex-direction: column;
        margin-bottom: 16px;
        
        label {
          font-family: Rubik;
          font-style: normal;
          font-weight: 500;
          font-size: 18px;
          line-height: 30px;
          color: #3f4144;
          margin-bottom: 10px;
        }
      
        .groupping-input {
          background-color: #f6f6f6;
          position: relative;
      
          input {
            &.input-item {
              width: auto;
              height: 50px;
              padding: 20px 20px;
              background: transparent;
              border-radius: 5px;
              border: none;
            }
        
            &:focus {
              outline: none;
              border: none;
            }
        
            &::placeholder {
              font-style: normal;
              font-size: 18px;
              line-height: 30px;
              color: #cacccf;
            }
          }
          
          i {
            cursor: pointer;
            position: absolute;
            padding-top: 12px;
            right: 20px;
          }
        }
      
        .input-invalid {
          font-size: 14px;
        }
      }

      button {
        &.submit-button {
          font-weight: 600;
          font-size: 18px;
          line-height: 30px;
          width: auto;
          height: 50px;
          border: none;
          border-radius: 5px !important;
          margin-top: 15px;
          background: #0f75bc;
          color: #ffffff;
        }
      }
    }
  }
}
