button {
  &.submit-button {
    font-weight: 600;
    font-size: 18px;
    line-height: 30px;
    width: auto;
    height: 50px;
    border: none;
    border-radius: 5px !important;
    margin-top: 15px;
    background: #0f75bc;
    color: #ffffff;

    &.loading {
      opacity: 0.4;
      cursor: not-allowed;
    }
  }
}
