.user-approval {
  h1 {
    font-family: Rubik;
    font-style: normal;
    font-weight: bold;
    font-size: 28px;
    line-height: 33px;
    letter-spacing: -0.04em;
    color: #3f4144;
    margin-bottom: 30px;
  }

  .content-card {
    margin-bottom: 20px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    background: #ffffff;
    border-radius: 5px;
    font-family: Rubik;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 21px;
    letter-spacing: -0.04em;
    color: #3f4144;
    .top {
      display: flex;
      height: 104px;
      border-bottom: 1px solid #cacccf;

      .icon {
        color: #0f75bc;
        margin-right: 5px;
      }

      .card-item {
        margin-top: 17px;
      }

      .card-name {
        padding: 23px;
        width: 35%;

        .email {
          font-weight: normal;
        }
      }

      .card-school {
        padding: 23px;
        display: flex;
        align-self: center;
        align-items: center;
        border-left: 2px solid #cacccf;
        height: 60px;
        width: 25%;
      }

      .card-date {
        padding: 23px;
        display: flex;
        align-self: center;
        align-items: center;
        border-left: 2px solid #cacccf;
        height: 60px;
        width: 20%;
      }

      .card-action {
        width: 20%;
        padding: 23px;
        display: flex;
        align-items: center;
        justify-content: flex-end;

        .button-more {
          width: 40px;
          height: 40px;
          border: 2px solid #0f75bc;
          color: #0f75bc;
          box-sizing: border-box;
          border-radius: 5px;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: 10px;
          cursor: pointer;

          &.active {
            color: #ffffff;
            background: #f08a2b;
            border: none;
          }
        }

        .buttons {
          position: relative;

          .button-action {
            width: 150px;
            height: 40px;
            border: none;
            color: #ffffff;
            background-color: #0f75bc;
            box-sizing: border-box;
            border-radius: 5px;
            display: flex;
            align-items: center;
            justify-content: space-around;
            cursor: pointer;

            p {
              padding-left: 10px;
              padding-right: 20px;
              border-right: 2px solid #ffffff;
              cursor: pointer;
            }
          }
          .button-reject {
            width: 150px;
            margin-top: 5px;
            position: absolute;
            height: 40px;
            border: none;
            color: #ffffff;
            background-color: #e72121;
            box-sizing: border-box;
            border-radius: 5px;
            display: flex;
            align-items: center;
            justify-content: space-around;
            cursor: pointer;

            p {
              padding-left: 10px;
              padding-right: 20px;
              cursor: pointer;
            }
          }
        }
      }
    }

    .bottom {
      padding: 23px;
      p {
        margin-bottom: 0;
      }

      .id-siswa {
        background-color: #d9d9da;
        font-family: Rubik;
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 21px;
        letter-spacing: -0.04em;
        padding: 13px;
        color: #3f4144;
        border-radius: 5px;
      }

      .level-siswa {
        background-color: #cfe3f2;
        font-family: Rubik;
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 21px;
        letter-spacing: -0.04em;
        padding: 13px;
        color: #0f75bc;
        border-radius: 5px;
      }

      .tahun-siswa {
        background-color: #fce8d5;
        font-family: Rubik;
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 21px;
        letter-spacing: -0.04em;
        padding: 13px;
        color: #f08a2b;
        border-radius: 5px;
      }

      .whatsapp-siswa {
        background-color: #e2fde5;
        font-family: Rubik;
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 21px;
        letter-spacing: -0.04em;
        padding: 13px;
        color: #619e67;
        border-radius: 5px;
        cursor: pointer;
        
        a {
          text-decoration: none;
        }
      }

      .instagram-siswa {
        background-color: #e6d8f0;
        font-family: Rubik;
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 21px;
        letter-spacing: -0.04em;
        padding: 13px;
        color: #833ab4;
        border-radius: 5px;
      }

      .nama-siswa {
        background-color: #d9e1f0;
        font-family: Rubik;
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 21px;
        letter-spacing: -0.04em;
        padding: 13px;
        color: #4267b2;
        border-radius: 5px;
      }
    }
  }
}
