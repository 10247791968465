// HEADER WEB
.header-web {
  .navbar {
    img {
      width: 130px;
    }

    .navbar-web {
      .navbar-nav {
        .nav-item {
          &.nav-link {
            font-family: Rubik;
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 21px;
            text-align: right;
            color: #cacccf;
            margin-right: 30px;

            &.active {
              color: #0f75bc;
            }
            &:hover {
              color: #0f75bc;
            }
          }
        }
      }

      .toggle-bahasa {
        background-color: #f2f2f2;
        border-radius: 5px;
        padding: 15px 8px;

        span {
          font-family: Rubik;
          font-style: normal;
          font-weight: bold;
          font-size: 18px;
          line-height: 21px;
          text-align: center;
          color: #cacccf;
          padding: 10px 20px;
          border-radius: 5px;
          cursor: pointer;
          background-color: #f2f2f2;
          &.active {
            color: #ffffff;
            background-color: #0f75bc;
          }
        }
      }
    }
  }

  .menu-mobile {
    position: absolute;
    left: 0;
    background-color: #fff;
    width: 100%;
    padding: 30px 0;

    .navbar-nav {
      margin-bottom: 10px;
      .nav-item {
        &.nav-link {
          font-family: Rubik;
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 21px;
          color: #cacccf;

          &.active {
            color: #0f75bc;
          }
        }

        &:hover {
          padding-left: 10px;
          border-bottom: 2px solid #0f75bc;
        }
      }
    }

    .toggle-bahasa {
      background-color: #f2f2f2;
      border-radius: 5px;
      padding: 15px 8px;
      width: 140px;

      span {
        font-family: Rubik;
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        line-height: 21px;
        text-align: center;
        color: #cacccf;
        padding: 10px 20px;
        border-radius: 5px;
        cursor: pointer;
        background-color: #f2f2f2;
        &.active {
          color: #ffffff;
          background-color: #0f75bc;
        }
      }
    }
  }
}

// FOOTER WEB
.footer-web {
  padding: 30px 0;

  .image {
    padding-bottom: 30px;
    margin-bottom: 30px;
    border-bottom: 2px solid #f6f6f6;
    img {
      width: 130px;
    }
  }

  .footer-wrapper {
    .item-footer {
      .label {
        margin-bottom: 10px;
        b {
          font-family: Rubik;
          font-style: normal;
          font-weight: bold;
          font-size: 18px;
          line-height: 30px;
          color: #3f4144;
        }
      }
      p {
        // max-width: 190px;
        font-family: Rubik;
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 30px;
        color: #3f4144;
      }

      .socials {
        display: flex;
        align-items: center;

        .social-item {
          margin-right: 8px;
          background-color: #0f75bc;
          border-radius: 50%;
          padding: 8px;
          display: flex;
          align-items: center;
          justify-content: center;
          color: #fff;

          &:last-child {
            margin-right: 0;
          }
        }
      }
    }
  }
}

@media (max-width: 991px) {
  // FOOTER WEB
  .footer-web {
    padding: 20px 0;

    .image {
      padding-bottom: 20px;
      margin-bottom: 20px;
      border-bottom: 2px solid #f6f6f6;
      img {
        width: 130px;
      }
    }

    .footer-wrapper {
      .item-footer {
        margin-bottom: 20px;
      }
    }
  }
}
