.pagination-component {
  margin-top: 30px;
  ul {
    &.pagination {
      justify-content: center;
      align-items: center;

      li {
        padding: 20px;
        font-family: Rubik;
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 24px;
        letter-spacing: -0.04em;
        color: #cacccf;

        &.active {
          color: #0f75bc;
        }

        &.next {
          margin-bottom: 5px;
          width: 40px;
          height: 40px;
          left: 1163px;
          top: 1541px;
          background: #0f75bc;
          border-radius: 5px;
          align-items: center;
          display: flex;
          justify-content: center;
          margin-left: 40px;
        }
        &.previous {
          margin-right: 40px;
          margin-bottom: 5px;
          width: 40px;
          height: 40px;
          left: 1163px;
          top: 1541px;
            background: #0f75bc;
          border-radius: 5px;
          align-items: center;
          display: flex;
          justify-content: center;
          border: 2px solid #cacccf;
        }
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .pagination-component-fe {
    margin-top: 30px;
    ul {
      &.pagination {
        justify-content: center;
        align-items: center;
        background: #f6f6f6;
        border-radius: 5px;
  
        li {
          padding: 9px;
          font-family: Rubik;
          font-style: normal;
          font-weight: 500;
          font-size: 20px;
          line-height: 24px;
          letter-spacing: -0.04em;
          color: #cacccf;
  
          &.active {
            color: #0f75bc;
          }
  
          &.next {
            width: 40px;
            height: 40px;
            left: 1163px;
            top: 1541px;
            border-radius: 5px;
            align-items: center;
            display: flex;
            justify-content: center;
            margin-left: 40px;
            text-decoration: none;
  
            a {
              text-decoration: none;
            }
          }
          &.previous {
            margin-right: 40px;
            // margin-bottom: 5px;
            width: 40px;
            height: 40px;
            left: 1163px;
            top: 1541px;
            border-radius: 5px;
            align-items: center;
            display: flex;
            color: #cacccf;
            justify-content: center;
            a {
              text-decoration: none;
            }
          }
        }
      }
    }
  }
  }
.pagination-component-fe {
  margin-top: 30px;
  ul {
    &.pagination {
      justify-content: center;
      align-items: center;
      background: #f6f6f6;
      border-radius: 5px;

      li {
        padding: 9px;
        font-family: Rubik;
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 24px;
        letter-spacing: -0.04em;
        color: #cacccf;

        &.active {
          color: #0f75bc;
        }

        &.next {
          width: 40px;
          height: 40px;
          left: 1163px;
          top: 1541px;
          border-radius: 5px;
          align-items: center;
          display: flex;
          justify-content: center;
          margin-left: 40px;
          text-decoration: none;

          a {
            text-decoration: none;
          }
        }
        &.previous {
          margin-right: 40px;
          // margin-bottom: 5px;
          width: 40px;
          height: 40px;
          left: 1163px;
          top: 1541px;
          border-radius: 5px;
          align-items: center;
          display: flex;
          color: #cacccf;
          justify-content: center;
          a {
            text-decoration: none;
          }
        }
      }
    }
  }
}
