@media screen and (max-width: 600px) {
  .detail-alumni {
    background: #F8F8F8;
    box-sizing: border-box;
    padding: 20px 20px;
  
    .wrapper-content {
      display: flex;
      align-items: center;

      img {
        border-radius: 30px;
        height: auto;
        width: 70px;
        height: 0px;
      }
      
      .info {
        margin-left: 15px!important;
        padding: 20px 50px 20px 50px;
        border-left: 4px solid rgba(15, 117, 188, 0.3);
  
        h2 {
          font-family: Rubik;
          font-style: normal;
          font-weight: bold;
          font-size: 24px;
          line-height: 28px;
          letter-spacing: -0.04em;
          color: #3F4144;
          margin-bottom: 30px;
        }
  
        .details {
          display: flex;
          flex-direction: column;
  
          .detail-info {
            display: flex;
            align-items: center;
            margin-bottom: 20px;
    
            .icon {
              color: #0f75bc;
              position: absolute;
            }
    
            p {
              margin-left: 60px;
              margin-bottom: 0;
              font-family: Rubik;
              font-style: normal;
              font-weight: 500;
              font-size: 18px;
              line-height: 21px;
              letter-spacing: -0.04em;
              color: #3F4144;
            }
          }
        }
      }
  
      .motto {
        background: #FFFFFF;
        border-radius: 5px;
        box-sizing: border-box;
        margin-top: 20px;
        padding: 20px;
  
        p {
          font-family: Rubik;
          font-style: normal;
          font-weight: 500;
          font-size: 18px;
          line-height: 26px;
          letter-spacing: -0.04em;
          color: #3F4144;
        }
      }
    }
  
  }
}

.detail-alumni {
    background: #F8F8F8;
    box-sizing: border-box;
    padding: 20px 20px;
  
    .wrapper-content {
      display: flex;
      align-items: center;

      img {
        border-radius: 30px;
        height: auto;
      }
      
      .info {
        margin-left: 50px;
        padding: 20px 50px 20px 50px;
        border-left: 4px solid rgba(15, 117, 188, 0.3);
  
        h2 {
          font-family: Rubik;
          font-style: normal;
          font-weight: bold;
          font-size: 24px;
          line-height: 28px;
          letter-spacing: -0.04em;
          color: #3F4144;
          margin-bottom: 30px;
        }
  
        .details {
          display: flex;
          flex-direction: column;
  
          .detail-info {
            display: flex;
            align-items: center;
            margin-bottom: 20px;
    
            .icon {
              color: #0f75bc;
              position: absolute;
            }
    
            p {
              margin-left: 60px;
              margin-bottom: 0;
              font-family: Rubik;
              font-style: normal;
              font-weight: 500;
              font-size: 18px;
              line-height: 21px;
              letter-spacing: -0.04em;
              color: #3F4144;
            }
          }
        }
      }
  
      .motto {
        background: #FFFFFF;
        border-radius: 5px;
        box-sizing: border-box;
        margin-top: 20px;
        padding: 20px;
  
        p {
          font-family: Rubik;
          font-style: normal;
          font-weight: 500;
          font-size: 18px;
          line-height: 26px;
          letter-spacing: -0.04em;
          color: #3F4144;
        }
      }
    }
  
  }