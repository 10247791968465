@media (max-width: 320px) {
  .card-list-fe {
    border: 2px solid #0f75bc;
    box-sizing: border-box;
    border-radius: 5px;
    padding: 15px 16px;
    margin-bottom: 16px;
    margin-left:2%;
    margin-right:2%;
  
    .content-card {
      img {
        height: 80px;
        width: 80px;
        border-radius: 50%;
        margin-right: 16px;
      }
  
      .info {
        p {
          margin-bottom: 0;
  
          &.nama {
            font-family: Rubik;
            font-style: normal;
            font-weight: bold;
            font-size: 15px;
            line-height: 30px;
            color: #3f4144;
          }
  
          &.kelas {
            font-family: Rubik;
            font-style: normal;
            font-weight: 600;
            font-size: 15px;
            line-height: 30px;
            color: #0f75bc;
          }
        }
      }
    }
  
    .button {
      .button-detail {
        background: #0f75bc;
        border-radius: 5px;
        color: #fff;
        text-decoration: none;
        padding: 15px 20px;
        font-family: Rubik;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 30px;
        text-align: center;
        cursor: pointer;
      }
    }
  }
}
.card-list-fe {
  border: 2px solid #0f75bc;
  box-sizing: border-box;
  border-radius: 5px;
  padding: 15px 16px;
  margin-bottom: 16px;
  margin-left:2%;
  margin-right:2%;

  .content-card {
    img {
      height: 80px;
      width: 80px;
      border-radius: 50%;
      margin-right: 16px;
    }

    .info {
      p {
        margin-bottom: 0;

        &.nama {
          font-family: Rubik;
          font-style: normal;
          font-weight: bold;
          font-size: 17px;
          line-height: 30px;
          color: #3f4144;
        }

        &.kelas {
          font-family: Rubik;
          font-style: normal;
          font-weight: 600;
          font-size: 17px;
          line-height: 30px;
          color: #0f75bc;
        }
      }
    }
  }

  .button {
    .button-detail {
      background: #0f75bc;
      border-radius: 5px;
      color: #fff;
      text-decoration: none;
      padding: 15px 20px;
      font-family: Rubik;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 30px;
      text-align: center;
      cursor: pointer;
    }
  }
}
