.alumni-list {
  .top-buttons {
    margin-bottom: 30px;
    .wrapper-title {
      h1 {
        font-family: Rubik;
        font-style: normal;
        font-weight: bold;
        font-size: 28px;
        line-height: 40px;
        letter-spacing: -0.04em;
        color: #3f4144;
        margin-bottom: 0;
        margin-right: 24px;
      }

      .sort-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #0f75bc;
        border-radius: 5px;
        height: 45px;
        width: 45px;
        cursor: pointer;
      }
    }

    .buttons {
      a {
        text-decoration: none;
      }
      
      .btn-top {
        border: 2px solid #0f75bc;
        box-sizing: border-box;
        border-radius: 5px;
        padding: 12px 16px;
        margin-right: 16px;
        color: #0f75bc;
        cursor: pointer;

        &:last-child {
          margin-right: 0;
        }

        span {
          font-family: Rubik;
          font-style: normal;
          font-weight: 600;
          font-size: 18px;
          line-height: 0px;
          text-align: right;
          letter-spacing: -0.04em;
          color: #0f75bc;
          padding-left: 10px;
        }
      }
    }
  }

  .content-card {
    display: flex;
    height: 104px;
    background: #ffffff;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    font-family: Rubik;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 21px;
    letter-spacing: -0.04em;
    color: #3f4144;
    margin-bottom: 20px;

    .icon {
      color: #0f75bc;
      margin-right: 5px;
    }

    .card-item {
      margin-top: 17px;
    }

    .card-name {
      padding: 23px;
      width: 30%;

      .email {
        font-weight: normal;
      }
    }

    .card-photo {
      padding: 23px;
      display: flex;
      align-self: center;
      align-items: center;
      border-left: 2px solid #cacccf;
      height: 60px;
      width: 20%;
    }

    .card-school {
      padding: 23px;
      display: flex;
      align-self: center;
      align-items: center;
      border-left: 2px solid #cacccf;
      height: 60px;
      width: 20%;
    }

    .card-status {
      padding: 23px;
      display: flex;
      align-self: center;
      align-items: center;
      border-left: 2px solid #cacccf;
      height: 60px;
      width: 10%;
    }

    .card-action {
      width: 20%;
      padding: 23px;
      display: flex;
      align-items: center;
      justify-content: flex-end;

      .button-delete {
        width: 40px;
        height: 40px;
        border: 2px solid #0f75bc;
        color: #0f75bc;
        box-sizing: border-box;
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 10px;
        cursor: pointer;

        &.active {
          color: #ffffff;
          background: #f08a2b;
          border: none;
        }
      }

      .button-edit {
        width: 40px;
        height: 40px;
        border: none;
        color: #ffffff;
        background-color: #0f75bc;
        box-sizing: border-box;
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: space-around;
        cursor: pointer;

        p {
          padding-left: 10px;
          padding-right: 20px;
          border-right: 2px solid #ffffff;
        }
      }
    }
  }
}
