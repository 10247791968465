@media screen and (max-width: 600px) {
  .about-school {
    display: flex;
    flex-direction: column;
    max-width: 100vw;
  
    .content-carousel {
      // background-color: black;
      // height: 270px;
      margin-bottom: 40px;
      font-family: Rubik;
      letter-spacing: -0.04em;
      border-radius: 5px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #ffffff;
  
      .wrapper-group {
        display: flex;
        flex-direction: column;
        position: relative;
          .background-wrapper {
            position: absolute;
            // background-color: #3f4144;
            left: 30px;
            bottom: 35%;
            height: 100px;
            width: 70%;
            opacity: .4;
          }
          .wrapper-desc {
            position: absolute;
            z-index: 9;
            left: 30px;
            text-align: left;
            bottom: 40%;
            padding: 0 20px;
            .title {
              font-style: normal;
              font-weight: bold;
              font-size: 25px;
              line-height: 50px;
              z-index: 2;
              color: #ebdf0d;
              text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
              margin-left: -10%;
              margin-top: -60%;
            }
    
            .description {
              background-color: #6b6b6baf;
              font-style: normal;
              font-weight: 500;
              font-size: 13px;
              line-height: 18px;
              letter-spacing: -0.04em;
              z-index: 2;
              color: #ffffff;
              position: absolute;
              margin-left: -10%;
              text-align: left;
              // margin-top: -30%;
              
            }
        }
  
  
        img {
          // position: absolute;
          z-index: -1;
          height: 400px;
          object-fit: cover;
          border-radius: 5px;
        }
      }
    }
  
    .content-body {
      display: flex;
      flex-direction: column;
      margin-bottom: 20px;
  
      .item-content {
        margin-bottom: 20px;
  
        .select-year {
          p {
            &.year-label {
              font-family: Rubik;
              font-style: normal;
              font-weight: 500;
              font-size: 18px;
              line-height: 30px;
              color: #3f4144;
              margin-bottom: 10px;
            }
          }
  
          .select-form {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
  
            select {
              height: 50px;
              width: 188px;
              background: #ffffff;
              border-radius: 5px;
              font-family: Rubik;
              font-style: normal;
              font-weight: 300;
              font-size: 18px;
              line-height: 30px;
              color: #cacccf;
              padding: 10px 16px;
              margin-right: 16px;
            }
  
            .arrow {
              background: #0f75bc;
              border-radius: 5px;
              width: 63px;
              height: 50px;
              display: flex;
              align-items: center;
              justify-content: center;
            }
          }
        }
  
        .color-one {
          background: #fbedc2;
          border: 2px solid;
          border-color: #ffd75c;
        }
  
        .color-two {
          background: #f96e6e;
          border: 2px solid;
          border-color: #c10d0d;
        }
  
        .color-three {
          background: #52b3f1;
          border: 2px solid;
          border-color: #0661a0;
        }
  
        .color-four {
          background: #B7B7B7;
          border: 2px solid;
          border-color: #878484;
        }
  
        .box-card {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          width: 100%;
          padding: 30px 0px 30px 30px;
          // background: #f6f6f6;
          border-radius: 5px;
          cursor: pointer;
  
          .box-item {
            display: flex;
            flex-direction: column;
  
            .blue-line {
              width: 45px;
              height: 3px;
              margin-left: -20px;
              background: #120576;
            }
  
            p {
              width: max-content;
              font-family: Rubik;
              font-style: normal;
              font-weight: 600;
              font-size: 20px;
              line-height: 28px;
              color: #120576;
              cursor: pointer;
            }
          }
        }
      }
    }
  
    .content-map {
      height: 200px;
    }
  }
}

  .about-school {
    display: flex;
    flex-direction: column;
    max-width: 100vw;
  
    .content-carousel {
      // background-color: black;
      // height: 270px;
      margin-bottom: 40px;
      font-family: Rubik;
      letter-spacing: -0.04em;
      border-radius: 5px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #ffffff;
  
      .wrapper-group {
        display: flex;
        flex-direction: column;
        position: relative;
          .background-wrapper {
            position: absolute;
            // background-color: #3f4144;
            left: 30px;
            bottom: 35%;
            height: 100px;
            width: 70%;
            opacity: .4;
          }
          .wrapper-desc {
            position: absolute;
            z-index: 9;
            left: 30px;
            text-align: left;
            bottom: 40%;
            padding: 0 20px;
            .title {
              font-style: normal;
              font-weight: bold;
              font-size: 42px;
              line-height: 50px;
              z-index: 2;
              color: #ebdf0d;
              text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
            }
    
            .description {
              background-color: #6b6b6baf;
              font-style: normal;
              font-weight: 500;
              font-size: 16px;
              line-height: 19px;
              letter-spacing: -0.04em;
              z-index: 2;
              color: #ffffff;
              position: absolute;
            }
        }
  
  
        img {
          // position: absolute;
          z-index: -1;
          height: 400px;
          object-fit: cover;
          border-radius: 5px;
        }
      }
    }
  
    .content-body {
      display: flex;
      flex-direction: column;
      margin-bottom: 20px;
  
      .item-content {
        margin-bottom: 20px;
  
        .select-year {
          p {
            &.year-label {
              font-family: Rubik;
              font-style: normal;
              font-weight: 500;
              font-size: 18px;
              line-height: 30px;
              color: #3f4144;
              margin-bottom: 10px;
            }
          }
  
          .select-form {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
  
            select {
              height: 50px;
              width: 188px;
              background: #ffffff;
              border-radius: 5px;
              font-family: Rubik;
              font-style: normal;
              font-weight: 300;
              font-size: 18px;
              line-height: 30px;
              color: #cacccf;
              padding: 10px 16px;
              margin-right: 16px;
            }
  
            .arrow {
              background: #0f75bc;
              border-radius: 5px;
              width: 63px;
              height: 50px;
              display: flex;
              align-items: center;
              justify-content: center;
            }
          }
        }
  
        .color-one {
          background: #ffda68;
          border: 2px solid;
          border-color: #ffd75c;
        }
  
        .color-two {
          background: #ff5c66;
          border: 2px solid;
          border-color: #c10d0d;
        }
  
        .color-three {
          background: #4ab8ff;
          border: 2px solid;
          border-color: #0661a0;
        }
  
        .color-four {
          background: #e7e3e3;
          border: 2px solid;
          border-color: #878484;
        }
  
        .box-card {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          width: 100%;
          padding: 30px 0px 30px 30px;
          // background: #f6f6f6;
          border-radius: 5px;
          cursor: pointer;
  
          .box-item {
            display: flex;
            flex-direction: column;
  
            .blue-line {
              width: 45px;
              height: 3px;
              margin-left: -20px;
              background: #120576;
            }
  
            p {
              width: max-content;
              font-family: Rubik;
              font-style: normal;
              font-weight: 600;
              font-size: 20px;
              margin-left: -20px;
              line-height: 28px;
              color: #120576;
              cursor: pointer;
            }
          }
        }
      }
    }
  
    .content-map {
      height: 200px;
    }
  }

