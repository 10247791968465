//mobile
@media only screen and (max-width: 600px) {
  .btn-profile {
    color: #fff;
    background-color: #0b5ed7;
    border-color: #0a58ca;
    width: 100%;
  }
  
  .layout-profil {
    display: flex;

    .sidebar-image {
      width: 397px !important;
      padding: 50px;
      height: 100% !important;
      position: fixed;
      background-image: url("../../../assets/image-sidebar.png");
      background-position: left;
      background-repeat: no-repeat;
      background-size: cover;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: -15px;

      .wrapper-card {
        .card-info {
          background-color: #fff;
          padding: 30px 16px;
          border-radius: 5px;
          text-align: center;
          width: 250px;
          margin-bottom: 16px;

          .icon-user {
            background-color: #e8e8e8;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100px;
            height: 100px;
            border-radius: 50%;
            font-size: 40px;
            color: #0f75bc;
            margin: auto;
            margin-bottom: 16px;
          }

          p {
            &.nama {
              font-family: Rubik;
              font-style: normal;
              font-weight: 600;
              font-size: 18px;
              line-height: 21px;
              color: #3f4144;
              margin-bottom: 16px;
              padding-bottom: 16px;
              border-bottom: 2px solid #e8e8e8;
            }

            &.sekolah {
              font-family: Rubik;
              font-style: normal;
              font-weight: normal;
              font-size: 16px;
              line-height: 19px;
              color: #3f4144;
              margin-bottom: 16px;
              padding-bottom: 16px;
              border-bottom: 2px solid #e8e8e8;
            }
          }

          .tanggal {
            display: flex;
            align-items: center;
            justify-content: center;
            color: #0f75bc;
            font-size: 18px;

            p {
              margin-left: 10px;
              font-family: Rubik;
              font-style: normal;
              font-weight: normal;
              font-size: 16px !important;
              line-height: 19px;
              text-align: center;
              color: #3f4144;
            }
          }
        }

        .card-whatsapp {
          background-color: #fff;
          padding: 16px;
          border-radius: 5px;
          text-align: center;
          width: 250px;
          margin-bottom: 16px;

          .whatsapp-wrapper {
            display: flex;
            align-items: center;
            justify-content: center;
            color: #60d26c;
            font-size: 20px;
            border: 2px solid #60d26c;
            border-radius: 5px;
            padding: 10px;
            cursor: pointer;

            p {
              margin-left: 6px;
              font-family: Rubik;
              font-style: normal;
              font-weight: 600;
              font-size: 16px;
              line-height: 30px;
              cursor: pointer;
              text-align: center;
            }
          }
        }

        .card-logout {
          background-color: #fff;
          padding: 16px;
          border-radius: 5px;
          text-align: center;
          width: 250px;
          margin-bottom: 16px;

          .btn-logout {
            background: #0f75bc;
            border-radius: 5px;
            font-family: Rubik;
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 30px;
            text-align: center;
            color: #ffffff;
            padding: 10px;
          }
        }
      }
    }

    .content {
      padding: 10px 3px !important;
      width: 97% !important;
      // margin-left: 300px;

      .top-bar {
        margin-bottom: 30px;

        .back-button {
          display: flex;
          align-items: center;
          cursor: pointer;
          margin-bottom: 30px;

          .icon-back {
            margin-right: 16px;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 35px;
            height: 35px;
            border-radius: 50%;
            background-color: #0f75bc;
            font-size: 20px;
            color: #fff;
            cursor: pointer;
          }

          p {
            margin-bottom: 0;
            cursor: pointer;
            font-family: Rubik;
            font-style: normal;
            font-weight: 600;
            font-size: 14px !important;
            line-height: 21px;
            color: #0f75bc;
          }
        }

        .top-menus {
          display: flex;
          align-items: center;

          .menus {
            display: flex;
            background-color: #f6f6f6;
            padding: 8px;
            border-radius: 5px;
            align-items: center;
            margin-right: 20px;

            a {
              font-family: Rubik;
              font-style: normal;
              font-weight: 600;
              font-size: 14px !important;
              line-height: 21px;
              letter-spacing: -0.04em;
              color: #cacccf;
              text-decoration: none;
              padding: 14px 20px;

              &.active {
                background: #0f75bc;
                border-radius: 5px;
                color: #fff;
              }
            }
          }

          .search {
            border: 3px solid #e8e8e8;
            box-sizing: border-box;
            border-radius: 5px;
            display: flex;
            align-items: center;
            padding: 5px;
            width: 400px;
            margin-top: -38%;
            margin-left: -78%;
            width: 70%;

            input {
              border: none;
              padding-left: 10px;
              width: 100%;

              &:focus {
                border: none;
                outline: none;
              }
            }

            .button-search {
              width: 100px;
              margin-left: 20px;
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 992px) {
  //web
  .layout-profil {
    display: flex;

    .sidebar-image {
      width: 300px;
      padding: 50px;
      height: 100vh;
      position: fixed;
      background-image: url("../../../assets/image-sidebar.png");
      background-position: left;
      background-repeat: no-repeat;
      background-size: cover;
      display: flex;
      align-items: center;
      justify-content: center;

      .wrapper-card {
        .card-info {
          background-color: #fff;
          padding: 30px 16px;
          border-radius: 5px;
          text-align: center;
          width: 250px;
          margin-bottom: 16px;

          .icon-user {
            background-color: #e8e8e8;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100px;
            height: 100px;
            border-radius: 50%;
            font-size: 40px;
            color: #0f75bc;
            margin: auto;
            margin-bottom: 16px;
          }

          p {
            &.nama {
              font-family: Rubik;
              font-style: normal;
              font-weight: 600;
              font-size: 18px;
              line-height: 21px;
              color: #3f4144;
              margin-bottom: 16px;
              padding-bottom: 16px;
              border-bottom: 2px solid #e8e8e8;
            }

            &.sekolah {
              font-family: Rubik;
              font-style: normal;
              font-weight: normal;
              font-size: 16px;
              line-height: 19px;
              color: #3f4144;
              margin-bottom: 16px;
              padding-bottom: 16px;
              border-bottom: 2px solid #e8e8e8;
            }
          }

          .tanggal {
            display: flex;
            align-items: center;
            justify-content: center;
            color: #0f75bc;
            font-size: 18px;

            p {
              margin-left: 10px;
              font-family: Rubik;
              font-style: normal;
              font-weight: normal;
              font-size: 16px;
              line-height: 19px;
              text-align: center;
              color: #3f4144;
            }
          }
        }

        .card-whatsapp {
          background-color: #fff;
          padding: 16px;
          border-radius: 5px;
          text-align: center;
          width: 250px;
          margin-bottom: 16px;

          .whatsapp-wrapper {
            display: flex;
            align-items: center;
            justify-content: center;
            color: #60d26c;
            font-size: 20px;
            border: 2px solid #60d26c;
            border-radius: 5px;
            padding: 10px;
            cursor: pointer;

            p {
              margin-left: 6px;
              font-family: Rubik;
              font-style: normal;
              font-weight: 600;
              font-size: 16px;
              line-height: 30px;
              cursor: pointer;
              text-align: center;
            }
          }
        }

        .card-logout {
          background-color: #fff;
          padding: 16px;
          border-radius: 5px;
          text-align: center;
          width: 250px;
          margin-bottom: 16px;

          .btn-logout {
            background: #0f75bc;
            border-radius: 5px;
            font-family: Rubik;
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 30px;
            text-align: center;
            color: #ffffff;
            padding: 10px;
          }
        }
      }
    }

    .content {
      padding: 50px 90px;
      width: 100%;
      margin-left: 300px;

      .top-bar {
        margin-bottom: 30px;

        .back-button {
          display: flex;
          align-items: center;
          cursor: pointer;
          margin-bottom: 30px;

          .icon-back {
            margin-right: 16px;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 35px;
            height: 35px;
            border-radius: 50%;
            background-color: #0f75bc;
            font-size: 20px;
            color: #fff;
            cursor: pointer;
          }

          p {
            margin-bottom: 0;
            cursor: pointer;
            font-family: Rubik;
            font-style: normal;
            font-weight: 600;
            font-size: 18px;
            line-height: 21px;
            color: #0f75bc;
          }
        }

        .top-menus {
          display: flex;
          align-items: center;

          .menus {
            display: flex;
            background-color: #f6f6f6;
            padding: 8px;
            border-radius: 5px;
            align-items: center;
            margin-right: 20px;

            a {
              font-family: Rubik;
              font-style: normal;
              font-weight: 600;
              font-size: 18px;
              line-height: 21px;
              letter-spacing: -0.04em;
              color: #cacccf;
              text-decoration: none;
              padding: 14px 20px;

              &.active {
                background: #0f75bc;
                border-radius: 5px;
                color: #fff;
              }
            }
          }

          .search {
            border: 3px solid #e8e8e8;
            box-sizing: border-box;
            border-radius: 5px;
            display: flex;
            align-items: center;
            padding: 5px;
            width: 400px;

            input {
              border: none;
              padding-left: 10px;
              width: 100%;

              &:focus {
                border: none;
                outline: none;
              }
            }

            .button-search {
              width: 100px;
              margin-left: 20px;
            }
          }
        }
      }
    }
  }
}
