.textarea-field {
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
  label {
    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 30px;
    color: #3f4144;
    margin-bottom: 10px;
  }

  textarea {
    &.textarea-item {
      width: auto;
      height: 150px;
      padding: 20px 20px;
      background: #f6f6f6;
      border-radius: 5px;
      border: none;
    }

    &:focus {
      border-color: #3f4144 !important;
    }

    &::placeholder {
      font-style: normal;
      font-size: 18px;
      line-height: 30px;
      color: #cacccf;
    }
  }

  .textarea-invalid {
    font-size: 14px;
  }
}
