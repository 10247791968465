.gallery-list {  
	.wrapper-button {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-bottom: 20px;

		h1 {
      font-family: Rubik;
      font-style: normal;
      font-weight: bold;
      font-size: 28px;
      line-height: 33px;
      letter-spacing: -0.04em;
      color: #3F4144;
    }

		.button-add {
			width: auto;
			height: 40px;
			padding: 20px 20px;
			border: 2px solid #0F75BC;
			color: #0F75BC;
			box-sizing: border-box;
			border-radius: 5px;
			display: flex;
			align-items: center;
			justify-content: center;
			cursor: pointer;

			font-family: Rubik;
			font-style: normal;
			font-weight: 600;
			font-size: 18px;
			line-height: 21px;
			text-align: center;
			letter-spacing: -0.04em;
			color: #0F75BC;
		}
	}

	.content-card {
		display: flex;
		height: 104px;
		background: #FFFFFF;
		box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
		border-radius: 5px;
		font-family: Rubik;
		font-style: normal;
		font-weight: bold;
		font-size: 18px;
		line-height: 21px;
		letter-spacing: -0.04em;
		color: #3F4144;
		margin-bottom: 20px;

		.icon {
			color: #0f75bc;
			margin-right: 5px;
		}

		.card-item {
			margin-top: 17px;
		}

		.card-name {
			padding: 23px;
			width: 35%;

			.code {
				font-weight: normal;
			}
		}

		.card-photo {
			padding: 23px;
			display: flex;
			align-self: center;
			align-items: center;
			border-left: 2px solid #CACCCF;
			height: 60px;
			width: 25%;
		}

		.card-status {
			padding: 23px;
			display: flex;
			align-self: center;
			align-items: center;
			border-left: 2px solid #CACCCF;
			height: 60px;
			width: 20%;
		}

		.card-action {
			width: 20%;
			padding: 23px;
			display: flex;
			align-items: center;
			justify-content: flex-end;

			.button-delete {
				width: 40px;
				height: 40px;
				border: 2px solid #0F75BC;
				color: #0F75BC;
				box-sizing: border-box;
				border-radius: 5px;
				display: flex;
				align-items: center;
				justify-content: center;
				margin-right: 10px;
				cursor: pointer;

				&.active {
					color: #FFFFFF;
					background: #F08A2B;
					border: none;
				}
			}

			.button-edit {
				width: 40px;
				height: 40px;
				border: none;
				color: #FFFFFF;
				background-color: #0F75BC;
				box-sizing: border-box;
				border-radius: 5px;
				display: flex;
				align-items: center;
				justify-content: space-around;
				cursor: pointer;

				p {
					padding-left: 10px;
					padding-right: 20px;
					border-right: 2px solid #FFFFFF;
				}
			}
		}
	}
}