.teacher-upload {
  h2 {
    font-family: Rubik;
    font-style: normal;
    font-weight: bold;
    font-size: 28px;
    line-height: 33px;
    letter-spacing: -0.04em;
    color: #3f4144;
    margin-bottom: 30px;
  }

  .content-teacher {
    box-shadow: 0px 0px 10px rgba(124, 87, 87, 0.1);
    background: #ffffff;

    .details {
      max-width: 400px;
      margin: auto;
      padding-top: 20px;

      .button-content {
        margin-top: 30px;

        label {
          font-family: Rubik;
          font-style: normal;
          font-weight: 500;
          font-size: 18px;
          line-height: 30px;
          color: #3f4144;
        }

        .cards {
          display: flex;
          overflow-x: scroll;
          margin-bottom: 40px;
        }

        .buttons {
          .button-cancel {
            border: 2px solid #0f75bc;
            box-sizing: border-box;
            border-radius: 5px;
            font-family: Rubik;
            font-style: normal;
            font-weight: bold;
            font-size: 16px;
            line-height: 30px;
            text-align: center;
            color: #0f75bc;
            padding: 10px;
            margin-right: 10px;
          }

          .button-save {
            border: 2px solid #0f75bc;
            box-sizing: border-box;
            border-radius: 5px;
            font-family: Rubik;
            font-style: normal;
            font-weight: bold;
            font-size: 16px;
            line-height: 30px;
            text-align: center;
            color: #fff;
            padding: 10px;
            background-color: #0f75bc;
          }
        }
      }

      label {
        font-family: Rubik;
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 30px;
        color: #3f4144;
        margin-bottom: 10px;
      }

      .input-image {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;

        &.no-file {
          background: #f6f6f6;
          border-radius: 5px;
          border: 2px #3f4144;
          height: 50px;
          cursor: pointer;
        }

        .trash-icon {
          position: absolute;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: #0f75bc;
          border-radius: 50%;
          width: 25px;
          height: 25px;
          right: 0;
          top: 0;
          cursor: pointer;
        }

        img {
          &.preview-content {
            width: 100%;
            height: 200px;
            object-fit: contain;
          }
        }
      }
    }
  }
}
