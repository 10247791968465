@media only screen and (max-width: 600px) {
  .layout-auth {
    background-image: url("../../../assets/auth-bgxx.png");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    padding: 60px 0;
  
    .wrapper-card {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      z-index: 2;
  
      .card-item {
        margin-top: 20px;
        padding: 22px;
        width: 440px;
        background: #ffffff;
        border-radius: 10px;
        font-family: Rubik;
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        text-align: center;
        color: #3f4144;
  
        a {
          text-decoration: none;
          font-weight: bold;
          cursor: pointer;
        }
      }
  
      .content-title {
        margin-bottom: 20px;
        width: 342px;
      }
    }
  
    .left-bg {
      position: relative;
      align-self: center;
      z-index: 1;
      width: auto;
      height: 608.23px;
      margin-right: -80px;
    }
  
    .right-bg {
      position: relative;
      align-self: center;
      z-index: 1;
      width: auto;
      height: 608.23px;
      margin-left: -80px;
    }
  }

}

.layout-auth {
  background-image: url("../../../assets/auth-bg.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 60px 0;

  .wrapper-card {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 2;

    .card-item {
      margin-top: 20px;
      padding: 22px;
      width: 440px;
      background: #ffffff;
      border-radius: 10px;
      font-family: Rubik;
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      text-align: center;
      color: #3f4144;

      a {
        text-decoration: none;
        font-weight: bold;
        cursor: pointer;
      }
    }

    .content-title {
      margin-bottom: 20px;
      width: 342px;
    }
  }

  .left-bg {
    position: relative;
    align-self: center;
    z-index: 1;
    width: auto;
    height: 608.23px;
    margin-right: -80px;
  }

  .right-bg {
    position: relative;
    align-self: center;
    z-index: 1;
    width: auto;
    height: 608.23px;
    margin-left: -80px;
  }
}

@media (max-width: 1440px) {
  .layout-auth {
    .wrapper-card {
      .card-content {
        width: 370px;
        background: #ffffff;
        border-radius: 10px;
      }

      .card-item {
        margin-top: 20px;
        padding: 22px;
        width: 370px;

        a {
          text-decoration: none;
        }
      }

      .content-title {
        margin-bottom: 20px;
        width: 280px;
      }
    }

    .left-bg {
      position: relative;
      align-self: center;
      z-index: 1;
      width: auto;
      height: 500px;
    }

    .right-bg {
      position: relative;
      align-self: center;
      z-index: 1;
      width: auto;
      height: 500px;
    }
  }
}

@media (max-width: 992px) {
  // tablet
}
@media (max-width: 576px) {
  // mobile
  .left-bg {
    display: none;
  }

  .right-bg {
    display: none;
  }

  .card-item {
    margin-bottom: 80px;
  }
}
