.user-setting {  
  h1 {
    font-family: Rubik;
    font-style: normal;
    font-weight: bold;
    font-size: 28px;
    line-height: 33px;
    letter-spacing: -0.04em;
    color: #3F4144;
    margin-bottom: 30px;
  }

  .content-card {
    display: flex;
    height: 104px;
    background: #FFFFFF;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    font-family: Rubik;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 21px;
    letter-spacing: -0.04em;
    color: #3F4144;
    margin-bottom: 20px;

    .icon {
      color: #0f75bc;
      margin-right: 5px;
    }

    .card-item {
      margin-top: 17px;
    }

    .card-name {
      padding: 23px;
      width: 25%;

      .email {
        font-weight: normal;
      }
    }

    .card-school {
      padding: 23px;
      display: flex;
      align-self: center;
      align-items: center;
      border-left: 2px solid #CACCCF;
      height: 60px;
      width: 25%;
    }

    .card-status {
      padding: 23px;
      display: flex;
      align-self: center;
      align-items: center;
      border-left: 2px solid #CACCCF;
      height: 60px;
      width: 15%;
    }

    .card-action {
      width: 15%;
      padding: 23px;
      display: flex;
      align-items: center;
      justify-content: flex-end;

      .button-delete {
        width: 40px;
        height: 40px;
        border: 2px solid #0F75BC;
        color: #0F75BC;
        box-sizing: border-box;
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 10px;
        cursor: pointer;

        &.active {
          color: #FFFFFF;
          background: #F08A2B;
          border: none;
        }
      }

      .button-edit {
        width: 40px;
        height: 40px;
        border: none;
        color: #FFFFFF;
        background-color: #0F75BC;
        box-sizing: border-box;
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: space-around;
        cursor: pointer;

        p {
          padding-left: 10px;
          padding-right: 20px;
          border-right: 2px solid #FFFFFF;
        }
      }
    }
  }
}